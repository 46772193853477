<template lang="pug">
.download
    v-card.ml-8(light)
        v-card-title.brand.white--text.mb-5 Download Client (version: {{ client.version }})
        v-card-subtitle(v-if="client.error")
            h2.red--text Unable to get client info
        v-card-text
            v-btn.white--text(
                @click="downloadFile()"
                color='brand'
                :disabled="isDisabled"
            ) Download
                v-icon(small) download


</template>

<script lang="js">
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

export default {
    name: 'DownloadClient',
    data () {
        return {
            client: {
                version: 'N/A',
                path: ''
            },
            isDisabled: true,
        };
    },
    computed: {
    },
    watch: {
    },
    mounted () {
        this.baseUrl = this.baseUrl();
        this.initialize();
    },
    methods: {
        initialize () {
            this.getClientInfo();
        },

        /**
         * constructs the base url when using fetch
         */
        baseUrl (){
            return `${window.location.protocol}//${window.location.hostname}:${CONFIG.dev ? CONFIG.devApiPort : window.location.port}`;
        },

        async getClientInfo (){
            try{
                const apiKey = this.$store.getters.apiKey;
                this.client = await fetch(`${this.baseUrl}/devopsapi/clientInfo`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'DC-Auth': apiKey
                    }
                }).then(res => {
                    if(res.status !== 200){
                        return {error: res.statusText};
                    }
                    return res.json();
                });
                if(this.client.error){
                    this.isDisabled = true;
                    console.error(this.client.error);
                    return;
                }
                this.isDisabled = false;
            } catch(err){
                this.isDisabled = true;
                console.error('initialize threw error');
                console.error(err);
            }
        },

        /**
         * Downloads the installer for the customer
         * - have to use fetch since axios doesn't support streams
         */
        downloadFile () {
            const fileName = this.client.path;
            const apiKey = this.$store.getters.apiKey;
            return fetch(`${this.baseUrl}/devopsapi/downloadClient`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DC-Auth': apiKey
                }
            }).then(res => {

                if (!window.WritableStream) {
                    streamSaver.WritableStream = WritableStream;
                    window.WritableStream = WritableStream;
                }

                const fileStream = streamSaver.createWriteStream(fileName);
                const writer = fileStream.getWriter();
                if (res.body.pipeTo) {
                    writer.releaseLock();
                    return res.body.pipeTo(fileStream);
                }

                const reader = res.body.getReader();
                const pump = () =>
                    reader
                        .read()
                        .then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));

                return pump();
            });
        },

    }
};

</script>

<style lang="scss" scoped>

</style>
